module.exports = {
  __init__: [ 'selectionVisuals', 'selectionBehavior' ],
  __depends__: [
    require(50),
    require(52)
  ],
  selection: [ 'type', require(55) ],
  selectionVisuals: [ 'type', require(57) ],
  selectionBehavior: [ 'type', require(56) ]
};
