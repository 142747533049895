'use strict';

/**
 * SVGs for elements are generated by the {@link GraphicsFactory}.
 *
 * This utility gives quick access to the important semantic
 * parts of an element.
 */

/**
 * Returns the visual part of a diagram element
 *
 * @param {Snap<SVGElement>} gfx
 *
 * @return {Snap<SVGElement>}
 */
function getVisual(gfx) {
  return gfx.select('.djs-visual');
}

/**
 * Returns the children for a given diagram element.
 *
 * @param {Snap<SVGElement>} gfx
 * @return {Snap<SVGElement>}
 */
function getChildren(gfx) {
  return gfx.parent().children()[1];
}

/**
 * Returns the visual bbox of an element
 *
 * @param {Snap<SVGElement>} gfx
 *
 * @return {Bounds}
 */
function getBBox(gfx) {
  return getVisual(gfx).select('*').getBBox();
}


module.exports.getVisual = getVisual;
module.exports.getChildren = getChildren;
module.exports.getBBox = getBBox;