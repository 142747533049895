'use strict';

var assign = require(204);

var BpmnModdle = require(17);

var packages = {
  bpmn: require(33),
  bpmndi: require(34),
  dc: require(35),
  di: require(36)
};

module.exports = function(additionalPackages, options) {
  return new BpmnModdle(assign({}, packages, additionalPackages), options);
};